import { createI18n } from "vue-i18n";
import zh from "./zh-TW.json";
import en from "./en-US.json";

export const i18n = createI18n({
  locale: "zh_tw",
  fallbackLocale: "zh_tw",
  allowComposition: true,//to use with Composition API
  legacy: false, // so that VueI18n still works with Options API
  globalInjection: true,// to inject in the component
  messages: {
    "zh_tw": zh,
    "en": en,
  },
})