import axios from "axios";

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_URL_REST_API,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
// 設置請求(request)的攔截器
axiosIns.interceptors.request.use(
  (config) => {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// 設置回應(response)的攔截器
axiosIns.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(`error`, error.response);
    if (error.response) {
      switch (error.response.status) {
        case 401:
          alert("驗證已過期，請重新登入");
          console.log(error.message);
          break;
        case 404:
          alert("你要找的頁面不存在");
          break;
      }
    }
    if (!window.navigator.onLine) {
      alert("網路出了點問題，請重新連線後重整網頁");
      return;
    }
    return Promise.reject(error);
  }
);
//
export default {
  async GET(url, params) {
    try {
      const res = await axiosIns.get(url, {
        params,
      });
      return res.data;
    } catch (res) {
      console.log("GET error", res);
      return Promise.reject(res.message);
    }
  },
  async DELETE(url, params) {
    try {
      const res = await axiosIns.delete(url, {
        params,
      });
      return res.data;
    } catch (res) {
      return Promise.reject(res.message);
    }
  },
  async POST(...arge) {
    try {
      const res = await axiosIns.post(...arge);
      return res.data;
    } catch (res) {
      return Promise.reject(res.message);
    }
  },
  async PUT(...arge) {
    try {
      const res = await axiosIns.put(...arge);
      return res.data;
    } catch (res) {
      return Promise.reject(res.message);
    }
  },
  async PATCH(...arge) {
    try {
      const res = await axiosIns.patch(...arge);
      return res.data;
    } catch (res) {
      return Promise.reject(res.message);
    }
  },
};
