<script setup>
(function () {
  const cx = "636d131665a164d66";
  const gcse = document.createElement("script");
  gcse.type = "text/javascript";
  gcse.async = true;
  gcse.src = "https://cse.google.com/cse.js?cx=" + cx;
  const s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(gcse, s);
})();
</script>

<template>
  <div class="search-overlay" id="search-overlay">
    <div class="search-overlay__inner">
      <div class="search-overlay__header">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-md-6 col-8">
              <div class="logo">
                <a href="index.html">
                  <img
                    src="@/assets/img/ssgesc/logo.png"
                    class="img-fluid"
                    alt="site logo"
                  />
                </a>
              </div>
            </div>
            <div class="col-md-6 col-4">
              <div class="search-content text-end">
                <span
                  class="mobile-navigation-close-icon"
                  @click="toggleClass('removeClass', 'active')"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="search-overlay__body">
        <gcse:searchbox-only></gcse:searchbox-only>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    // offcanvas searchbox
    toggleClass(addRemoveClass, className) {
      const el = document.querySelector("#search-overlay");
      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
};
</script>

<style lang="scss"></style>
