import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "@/api/store";
import { i18n } from "@/language";

const generateSubRoutes = (store, listName) => {
  return [
    ...store.getNavigationList[listName]
      .filter((route) => !route.is_custom)
      .map((route) => {
        if (route.file_name === "sign-up2") {
          return {
            path: route.file_name,
            redirect: { name: "sign-up" },
          };
        }
        if (route.file_name === "reservation2") {
          return {
            path: route.file_name,
            redirect: { name: "reservation" },
          };
        }
        return {
          name: route.file_name,
          path: route.file_name,
          component: () => import(`../views/${route.file_name}/index.vue`),
          beforeEnter: (to, from, next) => {
            to.params.path = route.path;
            to.params.file_name = route.file_name;
            to.params.path = route.path;
            next();
          },
        };
      }),
    ...store.getNavigationList[listName]
      .filter((route) => route.is_custom)
      .map((route) => ({
        name: route.file_name,
        path: route.file_name,
        component: () => import(`../views/dynamicPage/index.vue`),
        beforeEnter: (to, from, next) => {
          to.params.path = route.path;
          to.params.file_name = route.file_name;
          to.params.path = route.path;
          store.getDynamicPage(route.id);
          next();
        },
      })),
  ];
};

const createDynamicRouter = async () => {
  let store = useStore();
  // 如果 store 中還沒有路由，那麼從 API 中載入它們
  if (!store.getNavigationList.length) {
    await store.getNavigation(i18n.global.locale.value);
  }
  const routes = [
    {
      path: "/",
      redirect: "/zh_tw",
      meta: {
        zh_title: "首頁",
        en_title: "Homepage",
      },
    },
    {
      path: "/:locale",
      beforeEnter: (to, from, next) => {
        const locale = to.params.locale;
        if (!["en", "zh_tw"].includes(locale)) return next("zh_tw");
        if (i18n.global.locale.value !== locale) {
          i18n.global.locale.value = locale;
        }
        next();
      },
      children: [
        {
          path: ":pathMatch(.*)*",
          name: "NotFound",
          component: () => import("../views/sample/elements/404.vue"),
          meta: {
            zh_title: "404",
            en_title: "404",
          },
        },
        {
          path: "",
          name: "HomePage",
          component: () => import("../views/HomePage.vue"),
          meta: {
            zh_title: "首頁",
            en_title: "Homepage",
          },
        },
        {
          path: "new",
          name: "News",
          component: () => import("../views/new/index.vue"),
          meta: {
            zh_title: "最新消息",
            en_title: "News",
          },
        },
        {
          path: "new-inner/:innerId/:type",
          name: "NewsInner",
          component: () => import("../views/new/NewsInner.vue"),
          meta: {
            zh_title: "新聞詳情",
            en_title: "Details",
          },
        },
        {
          path: "about",
          name: "About",
          children: generateSubRoutes(store, "about"),
        },
        {
          path: "manufacturer-zone",
          name: "ManufacturerZone",
          children: generateSubRoutes(store, "manufacturer_zone"),
        },
        {
          path: "assistance",
          name: "Assistance",
          children: generateSubRoutes(store, "assistance"),
        },
      ],
    },
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
      // always scroll to top
      return { top: 0 };
    },
  });

  const initializeDynamicRoutes = async () => {
    const locale = router.currentRoute.value.params.locale;
    await store.getNavigation(locale);
    const currentPageName = router.currentRoute.value.name;

    const sections = ["about", "assistance", "manufacturer_zone"];

    //命名頁面標題
    const updateDocumentTitle = (title, defaultTitle) => {
      const titleMap = {
        en: `${title} - Shalun Green Energy Science City`,
        zh_tw: `${title} - 沙崙智慧綠能科學城`,
      };
      document.title = titleMap[locale] || defaultTitle;
    };

    sections.forEach((section) => {
      (store.getNavigationList[section] || []).forEach((page) => {
        if (page.file_name === currentPageName) {
          updateDocumentTitle(page.title);
        }
      });
    });
  };

  router.afterEach(async (to, from) => {
    const locale = router.currentRoute.value.params.locale;
    const mata = router.currentRoute.value.meta;
    await initializeDynamicRoutes();

    if (mata.zh_title && locale === "zh_tw") {
      document.title = mata.zh_title + " - 沙崙智慧綠能科學城";
    }
    if (mata.en_title && locale === "en") {
      document.title = mata.en_title + " - Shalun Green Energy Science City";
    }
  });

  return router;
};

export { createDynamicRouter };
