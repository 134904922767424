import API from "@/api/axios";
import { defineStore } from "pinia";

export const useStore = defineStore("store", {
  state: () => ({
    showSuccessMessage: false,
    showErrorMessage: false,
    newsLetters: [],
    homepageList: [],
    usefulLinkList: [],
    navigationList: {},
    total_views:0,
    news: {
      list: [],
      totalNum: 0,
      totalPageNum: 1,
      currentPage: 0,
    },
    videos: {
      list: [],
      totalNum: 0,
      totalPageNum: 1,
      currentPage: 0,
    },
    lawsList: [],
    dynamicPageContent: {},
  }),
  getters: {
    getUsefulLinkList: (state) => state.usefulLinkList,
    getVideoList: (state) => state.videoList,
    getNews: (state) => state.news,
    getHomepageList: (state) => state.homepageList,
    getViews: (state) => state.total_views,
    getNavigationList: (state) => state.navigationList,
    getLawsList: (state) => state.lawsList,
    getDynamicPageContent: (state) => state.dynamicPageContent,
  },
  actions: {
    async getNewsInfo(query) {
      try {
        const news = await API.GET("newInfos", {
          ...query,
        });

        (this.news.totalNum = news.total),
          (this.news.totalPageNum = Math.ceil(news.total / 9)),
          (this.news.currentPage = news.currentPage),
          (this.news.list = news.data);
      } catch (error) {
        console.log(error);
      }
    },
    async getHomepageInfos(language) {
      try {
        const list = await API.GET("homepage", {
          ...language,
        });
        this.homepageList = list;
      } catch (error) {
        console.log(error);
      }
    },
    async getNavigation(language) {
      try {
        const { website_menus: list, total_views } = await API.GET(
          `navigation/?language=${language}`
        );
        this.navigationList = list;
        this.total_views = total_views;
        return list;
      } catch (error) {
        console.log(error);
      }
    },
    async getUsefulLinks(language) {
      try {
        const { data: links } = await API.GET("usefulLinks", {
          ...language,
        });
        this.usefulLinkList = links;
      } catch (error) {
        console.log(error);
      }
    },
    async getVideos(query) {
      try {
        const { data: video } = await API.GET("videos", {
          ...query,
        });
        this.videos.list = video.data;
        this.videos.totalNum = video.total;
        this.videos.totalPageNum = Math.ceil(video.total / 9);
        this.videos.currentPage = video.currentPage;
      } catch (error) {
        console.log(error);
      }
    },
    async getLaws(query) {
      try {
        const { data: laws } = await API.GET("laws", {
          ...query,
        });
        this.lawsList = laws;
      } catch (error) {
        console.log(error);
      }
    },
    async postSubscriptions(query) {
      try {
        const { data: res } = await API.POST("subscriptions", {
          ...query,
        });
        this.showSuccessMessage = true;
        console.log(res);
      } catch (error) {
        this.showErrorMessage = true;
        console.log(error);
      }
    },

    async getNewsLetters() {
      try {
        const { data: res } = await API.GET("newsLetters");

        this.newsLetters = res;
      } catch (error) {
        console.log(error);
      }
    },
    async postConsultations(query) {
      try {
        const data = await API.POST("consultations", {
          ...query,
        });
        return data;
      } catch (error) {
        return error;
      }
    },
    async getDynamicPage(id) {
      try {
        const { data: res } = await API.GET(`websiteMenus/${id}`);
        this.dynamicPageContent = res;
        return res;
      } catch (error) {
        return error;
      }
    },
  },
});
