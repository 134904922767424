import { createApp } from "vue";
import "bootstrap-icons/font/bootstrap-icons.css";
import App from "./App.vue";
import { createDynamicRouter } from "./router";
import { createPinia } from "pinia";
import { i18n } from "./language";
import VueGtag from "vue-gtag-next";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "../src/assets/css/fontawesome-all.min.css";
import "animate.css/animate.min.css";
import "../src/assets/scss/style.scss";
import "swiper/css";
import "swiper/css/effect-fade";

const bootstrap = async() => {
  const app = createApp(App);
  const pinia = createPinia();

  app.use(pinia);

  const router = await createDynamicRouter();
  app.use(router);
  app.use(i18n);

  app.use(VueGtag, {
    property: {
      id: "G-GPZDZMQKDD",
      debug: true,
      defer: true,
      vueRouter: router,
    }
  });

  app.mount('#app');
}

bootstrap();