<template>
  <router-view />
  <OffcanvasSearchBox />
</template>

<script>
import WOW from "wow.js";
import OffcanvasSearchBox from "./components/sample/OffcanvasSearchBox.vue";

export default {
  name: "app",
  components: {
    OffcanvasSearchBox,
  },
  mounted() {
    new WOW().init();
  },
};
</script>
